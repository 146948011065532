<template>
  <div>
    <div class="container class-name post-breadcrumb-spacer">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mt-5">
          <h2 class="text-center pb-3">
            Choose your subject
          </h2>
          <div class="card">
            <div class="card-body">
              <label for="classSubject" :class="{ 'text-danger': errors.has('Class subject') }">Select course subject</label>
              <b-form-select v-model="selectedSubject" v-validate="'required'" class="custom-select"
                             value-field="name" text-field="name" :options="software"
                             name="classSubject" :class="{ 'text-danger': errors.has('Class subject') }" />
            </div>
            <div class="card-body">
              <b-button variant="primary" class="float-right" :disabled="!isFormValid"
                        @click="nextNav">
                Next
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonPlanSubject',
  data() {
    return {
      selectedSubject: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['lessonBuilder/getName']) {
        next('create/name');
      } else {
        next();
      }
    });
  },
  computed: {
    isFormValid() {
      return Object.keys(this.veeFields).every(key => this.veeFields[key].valid);
    },
    software() {
      return this.$store.getters['software/getAll'];
    },
    subject() {
      return this.$store.getters['lessonBuilder/getSubject'];
    }
  },
  created() {
    if (this.software.length === 0) {
      this.init();
    } else {
      this.selectedSubject = this.$store.getters['lessonBuilder/getSubject'].name;
    }
  },
  methods: {
    init() {
      this.$store.dispatch('software/loadSoftwareList').then(
        () => {
          this.selectedSubject = this.software[0].name;
        },
        error => {
          console.log(error);
        }
      );
    },
    findSelectedSoftware(subject) {
      return _.find(this.software, {name: this.selectedSubject});
    },
    /**
     * Commit name/code to store and move to next step
     */
    nextNav() {
      this.$store.dispatch('lessonBuilder/setSubject', this.findSelectedSoftware()).then(
        () => {
          this.$router.push({name: 'LessonPlanSelect'}).catch(err => {});
        }
      );

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
